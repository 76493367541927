import * as React from 'react';

const Main = () => {

	return (
		<div className="flex items-center justify-center h-screen ">
			<div className="p-30 rounded-lg shadow-xl text-center">
				<h1 className="text-2xl font-bold mb-4">Genie 1 has been Decommissioned</h1>
				<p className="text-gray-700 pt-6 mb-4">
					Please use Genie 2 instead.
				</p>
        <div className='pt-12'>
          <figure className="max-w-screen-md mx-auto text-center">
            <blockquote>
              <p className="text-2xl italic font-medium text-gray-100 dark:text-white">“If you’re brave enough to say goodbye, life will reward you with a new hello.”</p>
            </blockquote>
            <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
              <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700">
                <cite className="pe-3 font-medium text-gray-900 dark:text-white">Paulo Coelho</cite>
              </div>
          </figcaption>
          </figure>
        </div>
      </div>
	</div>
	);
}

export default Main;
